define("discourse/plugins/discourse-anon-limit/discourse/templates/connectors/below-footer/anon-limit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#unless this.currentUser}}
    {{#unless this.siteSettings.anonymous_can_search}}
      {{hide-application-header-buttons "search"}}
    {{/unless}}
  {{/unless}}
  
  
  */
  {
    "id": "x/UYK/x+",
    "block": "[[[41,[51,[30,0,[\"currentUser\"]]],[[[41,[51,[30,0,[\"siteSettings\",\"anonymous_can_search\"]]],[[[1,\"    \"],[1,[28,[35,1],[\"search\"],null]],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n\"]],[],false,[\"unless\",\"hide-application-header-buttons\"]]",
    "moduleName": "discourse/plugins/discourse-anon-limit/discourse/templates/connectors/below-footer/anon-limit.hbs",
    "isStrictMode": false
  });
});